<template>
  <ui-component-modal
    modalTitle="Create new location"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="this.selectedMeetingtypeIds.length === 0"
    :onClickCancel="onClickCancel"
    :onClickSave="createLocation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>Subscription</td>
            <td>
              <div class="select" :class="{ 'is-loading': isLoadingPackages }">
                <select v-model="selectedPackage">
                  <option :value="!selectedPackageId === 0">
                    Select a subscription
                  </option>
                  <option
                    v-for="pckg in pckgs"
                    :key="pckg.Id"
                    :value="pckg"
                    :selected="pckg.Id === selectedPackageId"
                  >
                    {{ pckg.Name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Location name</td>
            <td>
              <input type="text" v-model="newLocation.Name" class="input" />
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              <input type="text" v-model="newLocation.Address" class="input" />
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              <input type="text" v-model="newLocation.City" class="input" />
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <div class="select">
                <select v-model="newLocation.Country">
                  <option value="0">Select a country</option>
                  <option
                    v-for="country in countries"
                    :key="country.Id"
                    :value="country.Id"
                    :selected="country.Id === newLocation.Country"
                  >
                    {{ country.Name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Email address</td>
            <td>
              <input type="email" v-model="newLocation.Email" class="input" />
            </td>
          </tr>
          <tr>
            <td>Meeting types</td>
            <td>
              <div>
                <label
                  v-for="(meetingtypeId, index) in meetingtypeIds"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    :value="meetingtypeId"
                    :disabled="
                      possibleMeetingtypeIds.indexOf(meetingtypeId) === -1
                    "
                    v-model="selectedMeetingtypeIds"
                  />
                  {{ meetingtypeId | getMeetingtypeName }}
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import countryProvider from '@/providers/country'
import locationProvider from '@/providers/location'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      countries: [],
      isLoadingPackages: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      meetingtypeIds: [1, 2],
      newLocation: { Name: '', Address: '', City: '', Country: 0, Email: '' },
      packages: [],
      selectedMeetingtypeIds: [],
      selectedPackage: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationsPageState']),
    ...mapState('packageStore', ['pckgs']),

    possibleMeetingtypeIds() {
      if (this.selectedPackage) {
        let packageMeetingtypeIds = this.selectedPackage.Meetingtypes.map(
          (m) => m.MeetingtypeId
        )

        return this.meetingtypeIds.filter((f) =>
          packageMeetingtypeIds.includes(f)
        )
      }
      return []
    },

    selectedPackageId() {
      let id = 0

      if (this.selectedPackage) {
        id = this.selectedPackage.Id
      }

      return id
    },
  },

  created() {
    this.getAllPackages(this.channelId)
    this.getCountries()
  },

  methods: {
    ...mapMutations('locationStore', ['setLocationsPageState']),
    ...mapActions('packageStore', ['getAllPackages']),

    getCountries() {
      let self = this

      countryProvider.methods.getAllCountries().then((response) => {
        self.countries = response.data
      })
    },

    createLocation() {
      if (!this.isSaving) {
        this.isSaving = true

        let criteria = {
          ChannelId: this.channel.Id,
          Location: this.newLocation,
          PackageId: this.selectedPackage.Id,
          Meetingtypes: this.selectedMeetingtypeIds,
        }

        location.methods
        let self = this

        locationProvider.methods
          .createNewLocation(criteria)
          .then((response) => {
            if (response.status === 201) {
              self.isSavingSuccess = true

              let locationsPageState = JSON.parse(
                JSON.stringify(self.locationsPageState)
              )
              locationsPageState.locations.unshift(response.data)
              self.setLocationsPageState(locationsPageState)

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = true
          })
      }
    },
  },
}
</script>
